var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"py-0",attrs:{"three-line":"","dense":"","height":_vm.itemHeight}},[_c('v-list-item',{staticClass:"white",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-content',{staticClass:"black--text"},[_c('v-list-item-title',{staticClass:"d-inline-block text-truncate black--text pa-0 ma-0",attrs:{"title":_vm.$t('PlaceList.addressListItem_title') + ': ' + _vm.source.displayName}},[_vm._v(" "+_vm._s(_vm.source.displayName)+" ")]),_c('v-divider',{attrs:{"dense":""}}),_c('v-list-item-subtitle',{staticClass:"black--text pa-0 ma-0",staticStyle:{"max-width":"300px"}},[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","color":"black","title":_vm.$t('PlaceList.addressListItemSubtitle1_title') +
              '\nExtra info:' +
              (_vm.source.id ? '\nID: ' + _vm.source.id : '') +
              (_vm.source.active ? '\nactive: ' + _vm.source.active : '') +
              (_vm.source.amenityType
                ? '\namenityType: ' + _vm.source.amenityType
                : '') +
              (_vm.source.amenityName
                ? '\namenityName: ' + _vm.source.amenityName
                : '') +
              (_vm.source.formattedAddress
                ? '\nformattedAddress: ' + _vm.source.formattedAddress
                : '')}},[_vm._v("mdi-crosshairs-gps")]),_vm._v(" "+_vm._s(_vm.$t("PlaceList.addressListItemSubtitle1_text"))+" "+_vm._s(_vm.source.latitude + " | ")+" "+_vm._s(_vm.source.longitude)+" ")],1),(_vm.source.road)?_c('v-list-item-subtitle',{staticClass:"d-inline-block text-truncate black--text pa-0 ma-0",attrs:{"title":_vm.$t('PlaceList.addressListItemSubtitle2_title')}},[_vm._v(" "+_vm._s(_vm.source.houseNumber ? _vm.source.houseNumber + " | " : "")+" "+_vm._s(_vm.source.road ? _vm.source.road + "" : "")+" ")]):_vm._e(),(_vm.source.city)?_c('v-list-item-subtitle',{staticClass:"d-inline-block text-truncate black--text pa-0 ma-0",attrs:{"title":_vm.$t('PlaceList.addressListItemSubtitle3_title')}},[_vm._v(" "+_vm._s(_vm.source.city ? _vm.source.city + " | " : "")+" "+_vm._s(_vm.source.postcode ? _vm.source.postcode + " | " : "")+" "+_vm._s(_vm.source.country ? _vm.source.country + " | " : "")+" ")]):_vm._e()],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","title":_vm.$t('PlaceList.addressListItemShowAddressBtn')},on:{"click":function($event){return _vm.showAddressOnMap(_vm.source)}}},[_c('v-icon',{staticClass:"py-0 my-0",attrs:{"color":"black"}},[_vm._v("mdi-information")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }