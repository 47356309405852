<template>
  <div>
    <div v-if="localeLanguage == 'sk'">
      <!-- Názov aplikácie -->
      <h1 class="display-1 font-weight-bold mb-0">Dispečing Taxximo</h1>
      <p class="font-weight-bold mb-0">
        Verzia aplikácie: {{ versionDispatch }}
      </p>
      <p class="font-weight-bold mb-0">
        Verzia API: {{ versionServer }}
        {{ versionServerTime ? " | " + versionServerTime : "" }}
      </p>
      <p>
        Táto aplikácia je určená pre dispečerov, ktorí potrebujú neustále
        sledovať stav vodičov, objednávok a polohu vozidiel. Okrem toho umožňuje
        vytvárať objednávky, riadiť prácu vodičov a komunikovať s vodičmi.
      </p>
      <p>
        Aplikácia však nie je určená len pre dispečerov. Aj manažéri a majitelia 
        taxislužieb môžu sledovať výkonnosť a efektívnosť prevádzky, evidovať
        vozidlá a spravovať používateľov.
        Vytvárajú si reporty a podklady pre fakturáciu a mzdy, aby bolo všetko
        zabezpečené čo najjednoduchšie.
      </p>
      <p>
        Táto dispečingová aplikácia je súčasťou systému Taxximo, ktorý je
        navrhnutý pre prevádzku taxislužieb a prepravu osôb. Priamo komunikuje s
        centrálnym systémom, automatom na prideľovanie objednávok a mobilnými
        aplikáciami pre vodičov a zákazníkov.
      </p>
    </div>
    <div v-else-if="cz">
      <!-- Názov aplikácie -->
      <h1 class="display-1 font-weight-bold mb-0">Taxximo Dispečink</h1>
      <p class="font-weight-bold mb-0">Verze aplikace: {{ versionDispatch }}</p>
      <p class="font-weight-bold mb-0">
        Verze API: {{ versionServer }}
        {{ versionServerTime ? " | " + versionServerTime : "" }}
      </p>
      <p>
        Tato aplikace je určena pro dispečery, kteří potřebují neustále sledovat
        stav řidičů, objednávek a polohu vozidel. Kromě toho umožňuje vytvářet
        objednávky, řídit práci řidičů a zajistit, aby vše probíhalo bez
        problémů.
      </p>
      <p>
        Aplikace však není určena pouze pro dispečery. I manažeři a majitelé
        mají přístup k ní a mohou sledovat výkon a efektivitu provozu. Vytvářejí
        se reporty a podklady pro fakturaci a mzdy, aby bylo vše zajištěno co
        nejjednodušeji.
      </p>
      <p>
        Tato dispečerská aplikace je součástí systému Taxximo, který je navržen
        pro provoz taxislužeb a přepravu osob. Přímo komunikuje s centrálním
        systémem, automatem na přidělování objednávek a mobilními aplikacemi pro
        řidiče a zákazníky.
      </p>
    </div>
    <div v-else>
      <!-- Názov aplikácie -->
      <h1 class="display-1 font-weight-bold mb-0">Taxximo Dispatching</h1>
      <p class="font-weight-bold mb-0">
        Application version: {{ versionDispatch }}
      </p>
      <p class="font-weight-bold mb-0">
        API version: {{ versionServer }}
        {{ versionServerTime ? " | " + versionServerTime : "" }}
      </p>
      <p>
        This application is intended for dispatchers who need to constantly
        monitor the status of drivers, orders and vehicle locations. In
        addition, it allows you to create orders, control the work of drivers
        and ensure that everything goes smoothly.
      </p>
      <p>
        The application is not only intended for dispatchers. Even managers and
        owners have access to it and can monitor the performance and
        effectiveness of the operation. Reports and documents for billing and
        salaries are created, so that everything is as simple as possible.
      </p>
      <p>
        This dispatching application is part of the Taxximo system, which is
        designed for the operation of taxi services and passenger transport. It
        communicates directly with the central system, the order allocation
        machine and mobile applications for drivers and customers.
      </p>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      versionDispatch: process.env.VUE_APP_VERSION,
      versionServer: "",
      versionServerTime: "",
      localeLanguage: this.$store.state.appLocale,
    };
  },
  methods: {
    getApiVersion() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/app/info`)
        .then((response) => {
          this.versionServer = response.data.build.version;
          this.versionServerTime = response.data.build.time;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getApiVersion();
  },
};
</script>
