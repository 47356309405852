<template>
  <div>
    <div id="map" ref="map"></div>
    <!--<div id="switchDarkMapButton" ref="switchDarkMapButton">
      <v-card class="pa-2 ma-2">
        <v-switch
          class="pa-0 ma-0"
          dense
          hide-details
          v-model="switchDarkMapModel"
          label="Dark map"
        ></v-switch>
      </v-card>
    </div>-->
  </div>
</template>
<style scoped>
#map {
  height: 72vh;
}
</style>
<script>
export default {
  name: "Map",
  props: {
    passSelectedAddressOnMap: {
      type: Object,
      default: () => {},
    },
    passNearbyAddressesOnMap: {
      type: Array,
      default: () => [],
    },
    switchDarkMapModel: { type: Boolean, default: false },
    passSelectedGoogleAddressOnMap: {
      type: Object,
      default: [],
    },
    passSelectedGeocodeAddressOnMap: {
      type: Object,
      default: [],
    },
  },
  data() {
    return {
      map: null,
      mapCenter: { lat: 48.148, lng: 18.127 },
      //pri prvom zobrazení polohy na mape, zoomnuť na maximum mapu.
      firstZoom: true,

      markerAddress: [],
      markerNearbyAddress: [],

      markerDriver: "",
      pathDriver: "",
      markerImage: [],
      markerNearbyImage: [],
      boundsToAllDrivers: [],
      infoWindows: [],
      activeInfoWindow: null,
      //marker ktory sa pouziva na rozne ucely, napr. na indikaciu, kde je waypoint na mape
      temporaryMarker: null,
      //Kontrola ci je stlacena klavesa CRTL
      ctrlPressed: false,
      /*    markerImage2:
        [ ONLINE, OFFLINE, ON_BREAK, DELAY, WARNING, BUSY, ACCEPTED, WAITING, IN_PROGRESS, FINISHED ],
        */
      //parametre pre bublinku nad markerom vodica
      /*driverWindowLabelData: {
        driverId: null,
        orderId: null,
      },*/
      mapTheme: null,
      darkMap: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        {
          elementType: "labels.text.stroke",
          stylers: [{ color: "#242f3e" }],
        },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ],
    };
  },
  watch: {
    switchDarkMapModel: function (newValue, oldValue) {
      if (newValue == true) {
        this.mapTheme = this.darkMap;
      } else {
        this.mapTheme = null;
      }
      this.map.setOptions({ styles: this.mapTheme });
    },

    passSelectedAddressOnMap: function () {
      //console.log("PlaceGoogleMap.vue", this.passSelectedAddressOnMap);
      var addressType;
      var draggable;
      var tempPosition = this.passSelectedAddressOnMap;
      addressType = "ORIGINAL";
      draggable = false;
      this.manageAddressMarker(addressType, tempPosition, draggable);
      addressType = "SUGGESTED";
      draggable = true;
      this.manageAddressMarker(addressType, tempPosition, draggable);
      //Max zoom on map, when user first time selects address
      if (this.firstZoom == true) {
        this.map.setZoom(18);
        this.firstZoom = false;
      }
    },
    passNearbyAddressesOnMap: function () {
      console.log(
        "PlaceGoogleMap.vue - passNearbyAddressesOnMap",
        this.passNearbyAddressesOnMap
      );
      this.setNearbyMarkers(this.passNearbyAddressesOnMap);
    },
    passSelectedGoogleAddressOnMap: function () {
      const addressType = "GOOGLE";
      var tempPosition = this.passSelectedGoogleAddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
    passSelectedGeocodeAddressOnMap: function () {
      const addressType = "GEOCODE";
      var tempPosition = this.passSelectedGeocodeAddressOnMap;
      this.manageAddressMarker(addressType, tempPosition);
    },
  },
  computed: {},
  mounted() {
    //Mostik medzi plain javascript funkciou a vue metodov
    window.showOrderDetail = this.showOrderDetail;
    window.setDriverToDetailFromMap = this.setDriverToDetailFromMap;
    /*const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme == "true") {
        this.mapTheme = this.darkMap;
      } else {
        this.mapTheme = null;
      }
    }*/
    this.generateMarkers();
    this.generateNearbyMarkers();
    this.initMap();

    //Kontrola ci je nacitana kniznica google maps
    /*console.log("this.$parent", this.$parent);
    this.$parent.$on("googleMapsLoaded", () => {
      this.initMap();
    });*/
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //the center of the map
        zoom: 7,
        maxZoom: null, //18
        minZoom: 7,
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP, // typ mapy s cestami
        disableDefaultUI: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //umiestnenie prepínača typu mapy - dole v strede
          mapTypeIds: [
            google.maps.MapTypeId.ROADMAP,
            google.maps.MapTypeId.HYBRID,
          ],
        },
        zoomControl: true,
        /*zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.BOTTOM_CENTER, //BOTTOM_LEFT
        },*/
        scaleControl: false,
        styles: this.mapTheme,
      });
      var mapa = this.map;
      
      //posielanie aktualnych hranic mapy do parent komponentu
      mapa.addListener("idle", (event) => {
        var mapBounds;
        //console.log("previous actualMapBounds", mapBounds);
        mapBounds = mapa.getBounds();
        //console.log("actualMapBounds", mapBounds);
        this.$emit("actualMapBounds", mapBounds);
      });

      //Nacitanie polohy z markera pridaneho pravym kliknutim na mape
      mapa.addListener("rightclick", (event) => {
        if (this.markerAddress["SUGGESTED"]) {
          var lat = event.latLng.lat();
          var lng = event.latLng.lng();
          var position = { lat: lat, lng: lng };
          this.markerAddress["SUGGESTED"].setPosition(position);
          this.$emit("addressDragged", {
            addressType: "SUGGESTED",
            latitude: event.latLng.lat().toFixed(6),
            longitude: event.latLng.lng().toFixed(6),
          });
        }
      });
    },

    setNearbyMarkers(positionList) {
      console.log("setNearbyMarker - positionList: ", positionList);
      var markerNearbyAddress = [];
      markerNearbyAddress = this.markerNearbyAddress;
      //remove all markers in markerNearbyAddress array
      markerNearbyAddress.forEach((marker) => {
        marker.setMap(null);
      });
      positionList.forEach((address, index) => {
        var labelColor = "000";
        markerNearbyAddress[index] = new google.maps.Marker({
          position: { lat: address.latitude, lng: address.longitude },
          map: this.map,
          icon: this.markerNearbyImage[index],
          label: { text: "" + index, color: labelColor },
          draggable: false,
          title:
            "Driver ID: " +
            address.driverId +
            "\nScore: " +
            address.score +
            "\nDistance: " +
            address.distance +
            "\nSpeed: " +
            address.speed +
            "\nOrder ID: " +
            address.orderId +
            "\nCreated at: " +
            address.createdAt +
            "\nIndex: " +
            index,
        });
      });
      this.markerNearbyAddress = markerNearbyAddress;
    },

    manageAddressMarker(addressType, addressObject, draggable) {
      var markerPosition;
      var markerTitle;
      var markerLabel;
      if (addressObject != null) {
        markerPosition = new google.maps.LatLng(
          addressObject.latitude,
          addressObject.longitude
        );
      }
      //console.log("addressObject: ", addressObject);
      var markerOperation;
      if (
        addressObject == null &&
        !addressObject &&
        this.markerAddress[addressType] &&
        this.markerAddress[addressType] != null
      ) {
        //Odstranit marker ak uz neexistuje marker ale marker predtym existoval
        markerOperation = "REMOVE";
        //console.log("REMOVE1");
      } else if (
        addressObject.latitude == null &&
        addressObject.longitude == null &&
        this.markerAddress[addressType]
      ) {
        //Odstranit marker ak neexistuje poloha ale marker predtym existoval
        markerOperation = "REMOVE";
        //console.log("REMOVE2");
      } else if (
        addressObject != null &&
        addressObject &&
        addressObject.latitude != null &&
        addressObject.longitude != null &&
        this.markerAddress[addressType] &&
        this.markerAddress[addressType] != null
      ) {
        //presunut marker ak existuje poloha a existuje marker
        markerOperation = "CHANGE";
        //console.log("CHANGE");
      } else if (
        addressObject != null &&
        addressObject &&
        addressObject.latitude != null &&
        addressObject.longitude != null &&
        (!this.markerAddress[addressType] ||
          this.markerAddress[addressType] == null)
      ) {
        //nadefinovat novy marker ak neexistuje
        markerOperation = "NEW";
        //console.log("NEW", addressObject, this.markerAddress[addressType]);
      }
      switch (addressType) {
        case "ORIGINAL":
          //console.log("The BOARD!!");
          markerTitle =
            "Pôvodna uložená adresa\n(Je to pôvodná informatívna poloha.\nAk chcete zmeniť polohu, presuňťe ikonu s otáznikom/? a uložte formulár.)";
          markerLabel = {
            text: "X",
            color: "black",
            fontSize: "180%",
            fontWeight: "bold",
          };
          break;
        case "SUGGESTED":
          //console.log("The DESTINATION!!");
          markerTitle =
            "Nová navrhovaná adresa\n(pravým kliknutím na mape zmeníte novú polohu adresy\nalebo pretiahnite ikonu na iné miesto)";
          markerLabel = {
            text: "?",
            color: "white",
            fontSize: "180%",
            fontWeight: "bold",
          };
          break;
        case "GOOGLE":
          markerTitle = "Google adresa";
          markerLabel = {
            text: "G",
            color: "white",
            fontSize: "180%",
            fontWeight: "bold",
          };
          break;
        case "GEOCODE":
          markerTitle = "OSM adresa";
          markerLabel = {
            text: "O",
            color: "white",
            fontSize: "180%",
            fontWeight: "bold",
          };
          break;
        default:
        //console.log("Any!!");
      }
      if (markerOperation == "REMOVE") {
        //Odstranit marker ak neexistuje poloha ale marker predtym existoval
        this.markerAddress[addressType].setMap(null);
        this.markerAddress[addressType] = null;
      } else if (markerOperation == "CHANGE") {
        //presunut marker ak existuje poloha a existuje marker
        this.markerAddress[addressType].setPosition(markerPosition);
        this.map.setCenter(markerPosition);
      } else if (markerOperation == "NEW") {
        //nadefinovat novy marker ak neexistuje

        var markerImageSet = this.markerImage;
        //console.log("markerImageSet: ", markerImageSet);
        //console.log("addressType: ", addressType);

        this.markerAddress[addressType] = new google.maps.Marker({
          position: markerPosition,
          map: this.map,
          label: markerLabel,
          animation: google.maps.Animation.DROP,
          title: markerTitle,
          draggable: draggable,
          icon: {
            url: markerImageSet[addressType],
            size: new google.maps.Size(31, 44),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(16, 44),
            labelOrigin: new google.maps.Point(15, 15),
          },
          shape: {
            coords: [1, 15, 5, 5, 16, 1, 24, 5, 31, 15, 16, 44],
            type: "poly",
          },
        });
        //get position of dragged marker
        google.maps.event.addListener(
          this.markerAddress[addressType],
          "dragend",
          function (event) {
            //console.log("dragend");
            //this.setAddress(addressType, event.latLng);
            console.log("event.latLng: ", event.latLng);
            console.log(event.latLng.lat().toFixed(6));
            console.log(event.latLng.lng().toFixed(6));
            //emit event to parent component
            this.$emit("addressDragged", {
              addressType: addressType,
              latitude: event.latLng.lat().toFixed(6),
              longitude: event.latLng.lng().toFixed(6),
            });
          }.bind(this)
        );
        this.map.setCenter(markerPosition);
      }
    },
    generateMarkers() {
      var iconImage;
      var color1;
      var color2;
      var color3;
      var markerName;
      var svg;
      var tempMarkerImage = [];

      for (let i = 0; i < 4; i++) {
        markerName = "";
        color1 = "#000000";
        color2 = "#ffcc00";
        color3 = "#ffffff";
        //console.log(i, "x");
        switch (i) {
          case 0:
            markerName = "ORIGINAL";
            color2 = "#CCCCCC"; //"";
            color3 = "#CCCCCC"; //"";
            break;
          case 1:
            markerName = "SUGGESTED";
            color2 = "#990000";
            color3 = "#990000";
            break;
          case 2:
            markerName = "GOOGLE";
            color2 = "#2196F3";
            color3 = "#2196F3";
            break;
          case 3:
            markerName = "GEOCODE";
            color2 = "#FF9900";
            color3 = "#FF9900";
            break;
        }
        //console.log("markerName: ", markerName, " - color2: ", color2);
        iconImage = "";
        svg =
          '<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="31px" height="44px" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 502 713" xmlns:xlink="http://www.w3.org/1999/xlink">';
        svg =
          svg +
          '<path fill="' +
          color1 +
          '" d="M251 0c-138,0 -251,112 -251,251 0,189 251,462 251,462 0,0 251,-273 251,-462 0,-138 -112,-251 -251,-251z"/>';
        svg = svg + '<circle fill="' + color2 + '" cx="251" cy="251" r="235"/>';
        svg =
          svg +
          '<path fill="' +
          color3 +
          '" d="M461 357l-420 0c-16,-32 -25,-68 -25,-106 0,-38 9,-73 25,-105l420 0c16,32 25,67 25,105 0,38 -9,74 -25,106z"/>';
        svg = svg + "</svg>";
        iconImage = "data:image/svg+xml;base64," + btoa(svg);
        tempMarkerImage[markerName] = iconImage;
      }
      this.markerImage = tempMarkerImage;
      //console.log(this.markerImage);
    },

    generateNearbyMarkers() {
      var pinColor = "#FFFFFF";
      var tempMarkerImage = [];

      // Pick your pin (hole or no hole)
      var pinSVGHole =
        "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z";
      var labelOriginHole = new google.maps.Point(12, 15);
      var pinSVGFilled =
        "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z";
      var labelOriginFilled = new google.maps.Point(12, 9);

      var markerImage = {
        // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: pinSVGFilled,
        anchor: new google.maps.Point(12, 17),
        fillOpacity: 1,
        fillColor: pinColor,
        strokeWeight: 2,
        strokeColor: "white",
        scale: 2,
        labelOrigin: labelOriginFilled,
      };

      for (let i = 0; i < 20; i++) {
        pinColor = "#CCCCCC";
        //console.log(i, "x");
        switch (i) {
          case 0:
            pinColor = "#FF1744";
            break;
          case 1:
            pinColor = "#FF7043";
            break;
          case 2:
            pinColor = "#FFA726";
            break;
          case 3:
            pinColor = "#FFCA28";
            break;
          case 4:
            pinColor = "#FFEE58";
            break;
          case 5:
            pinColor = "#FFF176";
            break;
          case 6:
            pinColor = "#FFF59D";
            break;
          case 7:
            pinColor = "#FFF9C4";
            break;
          case 8:
            pinColor = "#FFFDE7";
            break;
          case 9:
            pinColor = "#FFFDFF";
            break;
        }
        markerImage = {
          // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
          path: pinSVGFilled,
          anchor: new google.maps.Point(12, 17),
          fillOpacity: 1,
          fillColor: pinColor,
          strokeWeight: 2,
          strokeColor: "white",
          scale: 2,
          labelOrigin: labelOriginFilled,
        };
        tempMarkerImage[i] = markerImage;
      }
      this.markerNearbyImage = tempMarkerImage;
      //console.log(this.markerImage);
    },
  },
};
</script>
