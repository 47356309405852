<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("PersonalSetting.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-cog</v-icon>
    </v-toolbar>
    <v-list class="mt-1">
      <v-list-item link>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> fa-lock </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("PersonalSetting.passwordTitle")
          }}</v-list-item-title>

          <v-list-item-subtitle>{{
            $t("PersonalSetting.passwordSubtitle")
          }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            outlined
            color="warning"
            @click.stop="
              showChangePasswordDialog = true;
              if ($refs.formChangePassword) {
                $refs.formChangePassword.resetValidation();
              }
              passwordNew = '';
              passwordNew2 = '';
              passwordOld = '';
            "
            >{{ $t("btnChange") }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item link>
        <v-list-item-avatar>
          <v-icon class="grey lighten-1" dark> mdi-monitor-dashboard </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-select
            v-model="dashboardSelectedItems"
            :items="$t('dashboardItems')"
            :label="$t('PersonalSetting.dashboardSelectedItems')"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 10">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 10" class="grey--text text-caption">
                (+{{ dashboardSelectedItems.length - 10 }} others)
              </span>
            </template>
          </v-select>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            outlined
            color="warning"
            @click.stop="setDashboardItemsCookie()"
            >{{ $t("btnChange") }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>

    <v-row no-gutters class="justify-center mt-16">
      <v-btn color="error" @click.stop="showLogoutDialog = true"
        >{{ $t("PersonalSetting.btnLogout") }}
      </v-btn>
    </v-row>

    <v-dialog
      v-model="showLogoutDialog"
      transition="dialog-top-transition"
      max-width="290"
    >
      <v-card>
        <v-toolbar color="secondary" dark flat>
          <v-toolbar-title>{{
            $t("PersonalSetting.dialogLogoutTitle")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon>mdi-logout</v-icon>
        </v-toolbar>
        <v-card-text>{{ $t("PersonalSetting.logoutText") }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="success" @click="showLogoutDialog = false">
            {{ $t("btnClose") }}
          </v-btn>

          <v-btn text color="error" @click.stop="logout()">
            {{ $t("PersonalSetting.btnLogout") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="showChangePasswordDialog"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("PersonalSetting.DialogPasswordChangeTitle")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>fa-lock</v-icon>
      </v-toolbar>
      <v-card>
        <v-form ref="formChangePassword">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="passwordOld"
                    :append-icon="showPassOld ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('PersonalSetting.passwordOld')"
                    prepend-icon="fa-lock"
                    :type="showPassOld ? 'text' : 'password'"
                    @click:append="showPassOld = !showPassOld"
                    required
                    :rules="[(v) => !!v || this.$t('msgRequiredField')]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="passwordNew"
                    :append-icon="showPassNew ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('PersonalSetting.passwordNew')"
                    prepend-icon="fa-lock"
                    :type="showPassNew ? 'text' : 'password'"
                    @click:append="showPassNew = !showPassNew"
                    required
                    :rules="passwordRules"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="passwordNew2"
                    :append-icon="showPassNew2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :label="$t('PersonalSetting.passwordNew2')"
                    prepend-icon="fa-lock"
                    :type="showPassNew2 ? 'text' : 'password'"
                    @click:append="showPassNew2 = !showPassNew2"
                    required
                    :rules="passwordRules"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click.stop="showChangePasswordDialog = false"
              >{{ $t("btnClose") }}</v-btn
            >
            <v-btn color="success" text @click.stop="changePassword()">{{
              $t("btnConfirm")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      showLogoutDialog: false,

      showChangePasswordDialog: false,
      showPassOld: false,
      showPassNew: false,
      showPassNew2: false,

      passwordOld: "",
      passwordNew: "",
      passwordNew2: "",
      //Validacia hesla
      passwordRules: [
        (v) => !!v || this.$t("msgRequiredField"),
        (v) => v.length >= 6 || this.$t("msgPasswordMinLength"),
        (v) => /[a-z]/.test(v) || this.$t("msgPasswordLowerCase"),
        (v) => /[0-9]/.test(v) || this.$t("msgPasswordNumber"),
      ],

      dashboardSelectedItems: [
        "utilizationRatio",
        "loadRatio",
        "immediateAvailabilityRatio",
        "counterPENDING",
        "sumPerformedOrders",
        "sumActiveDrivers",
        "sumBusyDrivers",
        "counterOnlineFREE",
        "counterON_BREAK",
        //"priceLocalityList",
        "driverOnlineItems",
        "driverOnBreakItems",
        //"worklogRequestItems",
      ],

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {
    //Zistenie zoznamu položiek pre Dashboard z Cookies
    var dashboardValue = this.getCookie("dashboardItems")
      ? JSON.parse(this.getCookie("dashboardItems"))
      : this.dashboardSelectedItems;
    this.dashboardSelectedItems = dashboardValue;
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("setUser", null);
          this.$router.push({ path: "/login" }); // login
        });
    },
    changePassword() {
      const isValid = this.$refs.formChangePassword.validate();
      if (isValid) {
        if (this.passwordNew != this.passwordNew2) {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t(
            "PersonalSetting.msgPasswordNotMatch"
          );
          this.snackbar.color = "error";
          return;
        }
        var user = firebase.auth().currentUser;
        var credentials = firebase.auth.EmailAuthProvider.credential(
          user.email,
          this.passwordOld
        );
        user
          .reauthenticateWithCredential(credentials)
          .then((response) => {
            user
              .updatePassword(this.passwordNew)
              .then((response) => {
                this.logout();
              })
              .catch((e) => {
                this.snackbar.show = "true";
                this.snackbar.message = e.message;
                this.snackbar.color = "error";
              });
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = e.message;
            this.snackbar.color = "error";
          });
      }
    },
    setDashboardItemsCookie() {
      this.setCookie(
        "dashboardItems",
        JSON.stringify(this.dashboardSelectedItems),
        30
      );
      this.snackbar.show = "true";
      this.snackbar.message = this.$t(
        "PersonalSetting.msgDashboardSelectedItemsSuccesfullySaved"
      );
      this.snackbar.color = "success";
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },
    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
  },

  mounted() {},
};
</script>
