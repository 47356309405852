<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>Roadmap</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-chart-gantt</v-icon>
    </v-toolbar>

    <v-timeline :dense="$vuetify.breakpoint.mobile">
      <!-- anchor to item -->
      <v-timeline-item
        v-for="item in items"
        :key="item.id"
        :color="item.type.color"
        :icon="item.type.icon"
        fill-dot
        :title="item.type.description"
      >
        <v-card :color="item.type.color" dark :id="'#' + item.id">
          <v-card-title style="word-break: normal">
            {{ item.title }}
            <v-spacer></v-spacer>
            <!-- mdi icon from menu -->
            <v-icon x-large v-if="item.icon">{{ item.icon }}</v-icon>
          </v-card-title>

          <v-card-text class="white black--text">
            <v-row>
              <v-col>
                <div class="text-caption mb-2">{{ item.text }}</div>
                <!--<v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                  ></v-img>
                </v-avatar>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                  ></v-img>
                </v-avatar>-->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <!-- Legend of colors: Green-finished, Blue-ongoing, Orange-planned -->
    <v-container fluid grid-list-md>
      <v-layout row wrap>
        <v-flex>
          <v-card flat>
            <v-card-title primary-title>
              <div>
                <span>Stavy implementácie podľa farieb</span>
              </div>
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="success">mdi-check-circle</v-icon>
                      <span>Zelená farba - Ukončené</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="info">mdi-dots-horizontal-circle</v-icon>
                      <span>Modrá farba - Prebieha implementácia</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="warning">mdi-help-circle</v-icon>
                      <span>Oranžová farba - Plánované</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon color="error">mdi-alert-circle</v-icon>
                      <span>Červená farba - Dôležitý milestone</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
const TYPE = {
  success: {
    color: "success",
    icon: "mdi-check-circle",
    description: "Úspešne ukončené",
  },
  error: {
    color: "error",
    icon: "mdi-alert-circle",
    description: "Dôležitý medzník",
  },
  info: {
    color: "info",
    icon: "mdi-dots-horizontal-circle",
    description: "Práve prebieha",
  },
  warning: {
    color: "warning",
    icon: "mdi-help-circle",
    description: "Pripravuje sa implementácia",
  },
};

export default {
  data: () => ({
    interval: null,
    items: [
      {
        id: 75,
        type: TYPE.warning,
        title: "Objednávkový widget na stránku taxislužby",
        text: "Programový kód na stránku taxislužby, kde si zákazník môže objednať taxi",
        icon: "mdi-application-outline",
      },
      {
        id: 74,
        type: TYPE.warning,
        title: "Kreditový systém pre vodičov",
        text: "Vodič si predplatí možnosť dostávať objednávky a sú mu odpočítavané z kreditu",
        icon: "mdi-account-credit-card-outline",
      },
      {
        id: 73,
        type: TYPE.warning,
        title: "Správa diaľničných známok",
        text: "Evidencia diaľničných známok jednotlivých vozidiel a ich platnosť",
        icon: "mdi-highway",
      },
      {
        id: 72,
        type: TYPE.warning,
        title: "Reklamný modul pre zákaznícky displej",
        text: "Zobrazenie reklám na zákazníckom displeji v vozidle",
        icon: "mdi-monitor-shimmer",
      },
      {
        id: 71,
        type: TYPE.warning,
        title: "Zákaznícky displej do vozidiel",
        text: "Aplikácia s informatívnym displejom o jazde do vozidiel",
        icon: "mdi-monitor-dashboard",
      },
      {
        id: 70,
        type: TYPE.warning,
        title: "Správa supportných incidentov",
        text: "Tikety na supportné incidenty, nahlasovanie, správa a riešenie, notifikácie",
        icon: "mdi-bug",
      },
      {
        id: 69,
        type: TYPE.warning,
        title: "Samoobslužný portál pre firemných zákazníkov",
        text: "Vytvorenie samostatného portálu pre firemných zákazníkov, ktorý bude obsahovať všetky potrebné informácie pre firemných zákazníkov. Portál bude obsahovať informácie o objednávkach, faktúrach, zmluvách, vozidlách, vodičoch, reportoch a ďalšie.",
        icon: "mdi-store",
      },
      {
        id: 68,
        type: TYPE.warning,
        title: "Skupiny používateľov",
        text: "Priradenie používateľov (vodičov, dispečerov, zákazníkov) do skupín a podpora na reporty s výberom používateľov podľa skupín. Prideľovanie objednávok automatom na skupinu používateľov.",
        icon: "mdi-account-group-outline",
      },
      {
        id: 67,
        type: TYPE.warning,
        title: "Reporty k platbe kartou",
        text: "Zatiaľ nie sú jasné požiadavky na reporty k platbe kartou.",
        icon: "mdi-account-credit-card-outline",
      },
      {
        id: 66,
        type: TYPE.warning,
        title: "Administrácia platieb kartou",
        text: "Zatiaľ nie sú jasné požiadavky na implementáciu administrácie platieb kartou",
        icon: "mdi-account-credit-card-outline",
      },
      {
        id: 65,
        type: TYPE.success,
        title: "Preklady",
        text: "Preloženie dispečingu: objekty, hlášky, názvy, popisy, ...",
        icon: "mdi-translate",
      },
      {
        id: 64,
        type: TYPE.warning,
        title: "Správa modulov",
        text: "Povoľovanie funkčností podľa modulov pre jednotlivé taxislužby",
        icon: "mdi-view-module-outline",
      },
      {
        id: 63,
        type: TYPE.warning,
        title: "Pracovné zmeny dispečerov",
        text: "Prihlasovanie a odhlasovanie z pracovných zmen",
        icon: "mdi-account-group",
      },
      {
        id: 62,
        type: TYPE.warning,
        title: "Dynamické ceny",
        text: "Navyšovanie cien podľa dopytu a ponuky v konkrétnych lokalitách.",
        icon: "mdi-cash-marker",
      },
      {
        id: 61,
        type: TYPE.warning,
        title: "Cenníky - Zľavy",
        text: "Generovanie zliav podľa prednastavených pravidiel, administrácia zľavového modulu",
        icon: "mdi-sale-outline",
      },
      {
        id: 60,
        type: TYPE.warning,
        title: "Počítadlá objednávok zákazníkov",
        text: "Zaznamenávanie počtu a typu objednávok podľa rôznych paramerov potrebných napríklad pre automatické generovanie zľav",
        icon: "mdi-counter",
      },
      {
        id: 59,
        type: TYPE.warning,
        title: "Platba kartou v aplikácii zákazníka",
        text: "Rozšírenie funkčnosti o platby kartou v aplikácii zákazníka, pre iOS aj Android",
        icon: "mdi-account-credit-card-outline",
      },
      {
        id: 58,
        type: TYPE.warning,
        title: "Logovanie zmien - Audit",
        text: "Rozšírené logovanie aktivít používateľov, Reporty k logom",
        icon: "mdi-post-outline",
      },
      {
        id: 57,
        type: TYPE.success,
        title: "Logovanie zmien - Objednávky",
        text: "Zaznamenávanie zmien objednávky a  doplnenie timeline do detailu objednávky",
        icon: "mdi-post-outline",
      },
      {
        id: 56,
        type: TYPE.warning,
        title: "Výpočet hodnotenia vodičov a zákazníkov",
        text: "Spracovanie hodnotení objednávok a na ich základe výpočet hodnotenia vodičov a zákazníkov",
        icon: "mdi-account-star-outline",
      },
      {
        id: 55,
        type: TYPE.warning,
        title: "Integrácia - Mailový server",
        text: "Odosielanie správ na mailový server, konfigurácia pre rôzne typy správ (zasielanie dokladov, zasielanie notifikácií)",
        icon: "mdi-email-outline",
      },
      {
        id: 54,
        type: TYPE.warning,
        title: "Generovanie dokumentov",
        text: "Generovanie dokumentov, priloženie do emailov, správa dokladov, správa šablón, sťahovanie a zasielanie dokumentov cez GUI",
        icon: "mdi-file-document-outline",
      },
      {
        id: 53,
        type: TYPE.warning,
        title: "Emailové správy",
        text: "Správa emailových správ, individuálne vytváranie šablón, parametrizácia šablón",
        icon: "mdi-email-outline",
      },
      {
        id: 52,
        type: TYPE.warning,
        title: "Používateľské role - Backend",
        text: "Úprava všetkých API, aby validovali oprávnenia podľa rolí",
        icon: "mdi-account-key",
      },
      {
        id: 51,
        type: TYPE.warning,
        title: "Servisné záznamy Vozidiel - Rozšírenie",
        text: "Notifikácie pre dlhodobejší servis (60k a 200k km), STK, EK",
        icon: "mdi-car-wrench",
      },
      {
        id: 50,
        type: TYPE.warning,
        title: "Problém manažment Zákazníka",
        text: "Nahlasovanie problémov z appky zákazníka, správa a riešenie v dispečingu, notifikácie",
        icon: "mdi-account-alert-outline",
      },
      {
        id: 49,
        type: TYPE.warning,
        title: "Reporty - Prevádzkové",
        text: "Reporty s informáciami o hodnotení vodičov, dĺžke trás, trvaní zrušených objednávok a pod.",
        icon: "mdi-chart-areaspline",
      },
      {
        id: 48,
        type: TYPE.warning,
        title: "Firemné jazdy v aplikácii zákazníka",
        text: "Rozšírenie funkčnosti o firemné jazdy z aplikácie zákazníka",
        icon: "mdi-cellphone-text",
      },
      {
        id: 47,
        type: TYPE.warning,
        title: "Filtre v aplikácii zákazníka",
        text: "Rozšírenie funkčnosti o filtre/požiadavky na jazdy v aplikácii zákazníka",
        icon: "mdi-cellphone-text",
      },
      {
        id: 46,
        type: TYPE.warning,
        title: "Časovky v aplikácii zákazníka",
        text: "Rozšírenie funkčnosti o časové objednávky služieb v aplikácii zákazníka",
        icon: "mdi-cellphone-text",
      },
      {
        id: 45,
        type: TYPE.info,
        title: "Bezdispečerská nadstavba - Aplikácia vodiča",
        text: "Smerovanie hovorov priamo na vodiča, bez dispečera",
        icon: "mdi-cellphone-text",
      },
      {
        id: 44,
        type: TYPE.success,
        title: "Servisné záznamy - Vozidlá",
        text: "Vytváranie servistných záznamov, evidencia servisných záznamov, notifikácie",
        icon: "mdi-car-wrench",
      },
      {
        id: 43,
        type: TYPE.success,
        title: "MIGRÁCIA DATABÁZ zo starého systému",
        text: "Presun časti údajov zo starého systému do nového: adresy, vodiči, vozidlá, používatelia, nastavenia taxislužieb, cenníky, lokality, stanovištia, firmy, zákazníci",
        icon: "mdi-database-outline",
      },
      {
        id: 42,
        type: TYPE.success,
        title: "PRODUKČNÉ NASADENIE",
        text: "Inštalácia a konfigurácia produkčného prostredia",
        icon: "mdi-exit-run",
      },
      {
        id: 41,
        type: TYPE.success,
        title: "Balíky služieb",
        text: "Povoľovanie rôznych služieb pre jednotlivé taxislužby a ich konfigurácia",
        icon: "mdi-account-group",
      },
      {
        id: 40,
        type: TYPE.success,
        title: "Reporty - Pracovné zmeny",
        text: "Reporty zobrazujúce informácie o pracovných zmenách vodičov",
        icon: "mdi-account-group",
      },
      {
        id: 39,
        type: TYPE.success,
        title: "iOS aplikácia zákazníkov - Základné funkčnosti",
        text: "Aplikácia pre zákazníkov, ktorá umožňuje vytvárať objednávky, zobrazovať ich stav a zmeny, chat, notifikácie. Verzia bez platieb kartou.",
        icon: "mdi-cellphone",
      },
      {
        id: 38,
        type: TYPE.success,
        title: "Android aplikácia zákazníkov - Základné funkčnosti",
        text: "Aplikácia pre zákazníkov, ktorá umožňuje vytvárať objednávky, zobrazovať ich stav a zmeny, chat, notifikácie. Verzia bez platieb kartou.",
        icon: "mdi-cellphone",
      },
      {
        id: 37,
        type: TYPE.success,
        title: "Reporty - Cenové",
        text: "Reporty zobrazujúce cenu za jazdu a cenové súčty, firemné jazdy",
        icon: "mdi-chart-areaspline",
      },
      {
        id: 36,
        type: TYPE.success,
        title: "Uzatváranie účtu vodičmi",
        text: "Úprava a uzatvorenie účtu vodičmi v aplikácii vodičov",
        icon: "mdi-cash",
      },
      {
        id: 35,
        type: TYPE.success,
        title: "Zobrazovanie cien v dispečingu",
        text: "Zobrazovanie cien v detaile objednávky, konzole a pod.",
        icon: "mdi-cash",
      },
      {
        id: 34,
        type: TYPE.success,
        title: "Cenníky - Výpočet cien objednávok",
        text: "Výpočet cien objednávok, výpočet cien jazd, výpočet cien za jazdu, príplatky",
        icon: "mdi-cash",
      },
      {
        id: 33,
        type: TYPE.success,
        title: "Používateľské role - frontend",
        text: "Editácia rolí (vrátane editačného API), Zobrazovenie položiek v menu a polí na obrazovkách podľa rolí",
        icon: "mdi-account-key",
      },
      {
        id: 32,
        type: TYPE.success,
        title: "Používatelia - Administrácia hesiel",
        text: "Zmena hesla vodičov, dispečerov administrátorom systému",
        icon: "mdi-account-key",
      },
      {
        id: 31,
        type: TYPE.success,
        title: "Individuálne nastavenie jázd - Dispečing a Vodič",
        text: "Iné nastavenie aplikácie vodiča pre každú objednávku, riadené lokalitou vyzdvihnutia: Zobrazenie tlačidiel, adriesy cieľa, telefónneho čísla zákazníka, rádiusu pre vynútenie zmeny stavu v blízkosti adresy vyzdvihnutia a pod.",
        icon: "mdi-cellphone-cog",
      },
      {
        id: 30,
        type: TYPE.success,
        title: "Integrácia - Telefónna ústredňa s Vodičom",
        text: "Vytvorenie hovoru vodiča a zákazníka s anonymizáciu čísla vodiča",
        icon: "mdi-phone-outline",
      },
      {
        id: 29,
        type: TYPE.success,
        title: "SMS správy",
        text: "Správa SMS správ, individuálne vytváranie šablón, parametrizácia šablón",
        icon: "mdi-phone-message-outline",
      },
      {
        id: 28,
        type: TYPE.success,
        title: "Integrácia - SMS brána",
        text: "Zasielanie SMS správ na telefónne číslo a sťahovanie doručených správ, podpora pre rôzne typy SMS správ (registračné, o pristavení vozidla, cene, link s online mapou)",
        icon: "mdi-phone-message-outline",
      },
      {
        id: 27,
        type: TYPE.success,
        title: "Integrácia - Telefónna ústredňa",
        text: "Zisťovanie údajov o aktuálnych telefonátoch z telefónnej ústredne, podpora pre rôzne typy ústrední",
        icon: "mdi-phone-outline",
      },
      {
        id: 26,
        type: TYPE.success,
        title: "KONZOLA - integrácia na ústredňu",
        text: "Načítavanie klapiek, načítavanie údajov o zákazníkovi podľa klapky",
        icon: "mdi-phone-outline",
      },
      {
        id: 25,
        type: TYPE.success,
        title: "Miesta - Správa adries",
        text: "Vytváranie, editácia, analýza polohy adries na mape, publikovanie",
        icon: "mdi-map-marker-outline",
      },
      {
        id: 24,
        type: TYPE.success,
        title: "Firemné jazdy - Administrácia",
        text: "Vytváranie firiem a prideľovanie požívateľov k firmám",
        icon: "mdi-store",
      },
      {
        id: 23,
        type: TYPE.success,
        title: "Firemné jazdy - Konzola",
        text: "Prideľovanie požívateľov k firmám, vytváranie firemných jázd",
        icon: "mdi-store",
      },
      {
        id: 22,
        type: TYPE.success,
        title: "KONZOLA dispečera - OPTIMALIZÁCIA",
        text: "Zníženie klikania, optimalizácia zobrazovania na mobile, doplnenie inteligentnejších funkcií na rýchlejšie používanie",
        icon: "mdi-map",
      },
      {
        id: 21,
        type: TYPE.success,
        title: "Ovládanie automatu - Konzola",
        text: "Zapínanie, vypínanie, stav automatu",
        icon: "mdi-robot-confused-outline",
      },
      {
        id: 20,
        type: TYPE.success,
        title: "Oznamy - Dashlet a Administrácia",
        text: "Administrácia oznamov, zobrazovanie oznamov v dashboarde",
        icon: "mdi-bell-outline",
      },
      {
        id: 19,
        type: TYPE.success,
        title: "Vozidlá - Správa fotiek vozidiel",
        text: "Administrácia a nahrávanie fotiek vozidiel",
        icon: "mdi-car",
      },
      {
        id: 18,
        type: TYPE.success,
        title: "Dashboard - Online štatistiky a zoznamy",
        text: "Návrh a vytvorenie štatistických algoritmov, zoznam aktívnych vodičov, zoznam vodičov na prestávke, zoznam žiadostí k pracovných zmenám",
        icon: "mdi-chart-areaspline",
      },
      {
        id: 17,
        type: TYPE.success,
        title: "Pracovné zmeny Vodičov - Konzola",
        text: "Prihlasovanie a odhlasovanie z pracovných zmen, schvaľovanie ukončenia zmeny, schvaľovanie prestávky, Súvisiace API",
        icon: "mdi-account-tie",
      },
      {
        id: 16,
        type: TYPE.success,
        title: "Dashboard - Platforma",
        text: "Dizajn dashletov a základné informácie, Súvisiace API",
        icon: "mdi-view-dashboard-outline",
      },
      {
        id: 15,
        type: TYPE.success,
        title: "Cenníky - Administrácia",
        text: "Vytvorenie a nastavovanie cenníka, generovanie cenových položiek podľa lokalít, editácia cenových položiek, Súvisiace API",
        icon: "mdi-cash",
      },
      {
        id: 14,
        type: TYPE.success,
        title: "Lokality - Administrácia",
        text: "Nastavovanie, kreslenie lokalít: lokalita pre vyzdvihnutie v appke, lokality pre ceny, stanovištia, Súvisiace API",
        icon: "mdi-web",
      },
      {
        id: 13,
        type: TYPE.success,
        title: "Reporty - Základné",
        text: "Základné reporty (Počty objednávok podľa dní a mesiacov), Súvisiace API",
        icon: "mdi-chart-bar",
      },
      {
        id: 12,
        type: TYPE.success,
        title: "Reporty - Platforma",
        text: "Podpora pre reporty, konfigurovanie reportov (Príprava platformy: pluginy, layouty, výber rozsahu vstupných dát, nastavovanie stĺpcov, filtrovanie, usporiadanie, export), Súvisiace API",
        icon: "mdi-chart-bar",
      },
      {
        id: 11,
        type: TYPE.success,
        title: "KONZOLA dispečera",
        text: "Základné funkcionality konzoly - nová objednávka, poloha vodičov, zoznam objednávok, chat, editácia objednávok..., Súvisiace API",
        icon: "mdi-map",
      },
      {
        id: 10,
        type: TYPE.success,
        title: "Správa Dispečera",
        text: "Vytvorenie obrazoviek (zoznam, detail, vytváranie a pod.), Súvisiace API",
        icon: "mdi-card-account-phone-outline",
      },
      {
        id: 9,
        type: TYPE.success,
        title: "Správa Vodiča",
        text: "Vytvorenie obrazoviek (zoznam, detail, vytváranie a pod.), Súvisiace API",
        icon: "mdi-account-tie",
      },
      {
        id: 8,
        type: TYPE.success,
        title: "Správa Taxislužby - základné nastavenia",
        text: "Vytvorenie obrazoviek (zoznam, detail, vytváranie a pod.), nastavenie konfigurácie, nastavenie informácií o taxislužbe, Súvisiace API",
        icon: "mdi-city",
      },
      {
        id: 7,
        type: TYPE.success,
        title: "Správa Vozidla",
        text: "Vytvorenie obrazoviek (zoznam, detail, vytváranie a pod.), Súvisiace API",
        icon: "mdi-car",
      },
      {
        id: 6,
        type: TYPE.success,
        title: "Správa Objednavky",
        text: "Vytvorenie obrazoviek (zoznam, detail, vytváranie a pod.), Zobrazovenie všetkých údajov v detaile, okrem ceny (cena bude doplnená neskôr), Súvisiace API",
        icon: "mdi-road-variant",
      },
      {
        id: 5,
        type: TYPE.success,
        title: "Preklady - príprava platformy na multi-jazykovosť",
        text: "Pridanie i18n knižnice na preklady a základné konfigurácie",
        icon: "mdi-translate",
      },
      {
        id: 4,
        type: TYPE.success,
        title: "Dispečing - Vuex",
        text: "Pridanie a kongigurácia knižnice vuex",
        icon: "mdi-vuejs",
      },
      {
        id: 3,
        type: TYPE.success,
        title: "Dispečing - Router frameworku",
        text: "Pridanie a konfigurácia knžnice router na navigáciu v aplikácii",
        icon: "mdi-vuejs",
      },
      {
        id: 2,
        type: TYPE.success,
        title: "Dispečing - Vuetify platforma",
        text: "Pridanie material dizajn framework pre grafické komponenty - VUETIFY",
        icon: "mdi-vuejs",
      },
      {
        id: 1,
        type: TYPE.success,
        title: "Iniciácia projektu",
        text: "Úvodné rozbehanie projektu, vytvorenie repozitára, výber témy, vytvorenie konfigurácie",
        icon: "mdi-vuejs",
      },
    ],
    nonce: 2,
  }),

  mounted() {
    //jump to anchor #34 2 seconds after page is loaded
    setTimeout(() => {
      //this.jumpToAnchor("#29");
      this.jumpToAnchor();
    }, 500);
  },
  methods: {
    jumpToAnchor() {
      //console.log("jump to anchor", anchor);
      //find element in items with lowest id which has type != TYPE.success
      const anchor = this.items
        .filter((item) => item.type !== TYPE.success)
        .sort((a, b) => a.id - b.id)[0].id;
      const element = document.getElementById("#" + (anchor + 1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
  },
};
</script>