<template>
  <!--<div class="item">
    <div class="id">{{ source.id }} - {{ source.type }}</div>
  </div>-->

  <v-list three-line dense class="py-0" :height="itemHeight">
    <!--<v-list-item dense>-->
    <v-list-item class="white" dense>
      <template v-slot:default="{ active }">
        <v-list-item-content class="black--text">
          <v-list-item-title
            class="d-inline-block text-truncate black--text pa-0 ma-0"
            :title="
              $t('PlaceList.addressListItem_title') + ': ' + source.displayName
            "
          >
            {{ source.displayName }}
          </v-list-item-title>
          <v-divider dense></v-divider>
          <!-- GPS poloha miesta -->
          <v-list-item-subtitle
            class="black--text pa-0 ma-0"
            style="max-width: 300px"
            ><v-icon
              dense
              color="black"
              class="pa-0 ma-0"
              :title="
                $t('PlaceList.addressListItemSubtitle1_title') +
                '\nExtra info:' +
                (source.id ? '\nID: ' + source.id : '') +
                (source.active ? '\nactive: ' + source.active : '') +
                (source.amenityType
                  ? '\namenityType: ' + source.amenityType
                  : '') +
                (source.amenityName
                  ? '\namenityName: ' + source.amenityName
                  : '') +
                (source.formattedAddress
                  ? '\nformattedAddress: ' + source.formattedAddress
                  : '')
              "
              >mdi-crosshairs-gps</v-icon
            >
            {{ $t("PlaceList.addressListItemSubtitle1_text") }}
            {{ source.latitude + " | " }}
            {{ source.longitude }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="source.road"
            class="d-inline-block text-truncate black--text pa-0 ma-0"
            :title="$t('PlaceList.addressListItemSubtitle2_title')"
          >
            {{ source.houseNumber ? source.houseNumber + " | " : "" }}
            {{ source.road ? source.road + "" : "" }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="source.city"
            class="d-inline-block text-truncate black--text pa-0 ma-0"
            :title="$t('PlaceList.addressListItemSubtitle3_title')"
          >
            {{ source.city ? source.city + " | " : "" }}
            {{ source.postcode ? source.postcode + " | " : "" }}
            {{ source.country ? source.country + " | " : "" }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- Bocne ikony -->
        <v-list-item-action>
          <!-- <v-icon
            color="black"
            :title="$t('source_tooltip.' + source.source)"
            class="mx-auto pb-0"
          >
            {{
              source.source == "DISPATCH_TAXISERVICE"
                ? "mdi-face-man-outline"
                : source.source == "DISPATCH_TAXXIMO"
                ? "mdi-face-man-outline"
                : source.source == "DISPATCH_BROKER"
                ? "mdi-face-man-outline"
                : source.source == "DRIVER_ANDROID"
                ? "mdi-account-tie-outline"
                : source.source == "CUSTOMER_ANDROID"
                ? "mdi-human-greeting-variant"
                : source.source == "CUSTOMER_IOS"
                ? "mdi-human-greeting-variant"
                : source.source == "BRAND_ANDROID"
                ? "mdi-human-greeting-variant"
                : source.source == "BRAND_IOS"
                ? "mdi-human-greeting-variant"
                : "mdi-alien-outline"
            }}</v-icon
          >
          <v-icon
            color="black"
            :title="$t('orderType_tooltip.' + source.type)"
            class="mx-auto pb-0"
          >
            {{
              source.type == "INSTANT"
                ? "mdi-bell-circle-outline"
                : source.type == "TIME"
                ? "mdi-clock-outline"
                : source.type == "STREET"
                ? "mdi-nature-people"
                : source.type == "DIRECT"
                ? "mdi-directions"
                : source.type == "REPEATED"
                ? "mdi-calendar-refresh"
                : source.type == "AIRPORT"
                ? "mdi-airplane-clock"
                : "mdi-alien-outline"
            }}</v-icon
          >-->
          <v-btn
            icon
            @click="showAddressOnMap(source)"
            :title="$t('PlaceList.addressListItemShowAddressBtn')"
          >
            <v-icon color="black" class="py-0 my-0">mdi-information</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-list-item>

    <!--<v-divider></v-divider>-->
  </v-list>
</template>

<script>
import { StatusColor } from "../enums/OrderEnum";
import * as moment from "moment/moment";
export default {
  name: "item",
  props: {
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    itemHeight: {
      type: String,
      default: 10,
    },
  },
  data() {
    return {
      StatusColor,
    };
  },
  watch: {
    source: function (newValue, oldValue) {
      console.log("source ", newValue);
    },
  },
  methods: {
    getStatusColor(status) {
      return this.StatusColor[status];
    },

    showAddressOnMap(source) {
      console.log("PlaceAddressListItem.vue - showAddressOnMap ", source);
      this.$emit("showAddressOnMap", source);
    },
  },
};
</script>
